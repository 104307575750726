<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search bot actions
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by bot, user steamid or trade id"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading"
        @pagination="onPagination"
      >
        <template #item.bot="{ value }">
          <h5
            class="text-caption grey100--text"
            v-text="value"
          />
        </template>

        <template #item.tradeId="{ value }">
          <a
            v-if="value"
            :href="`https://steamcommunity.com/tradeoffer/${value}`"
            target="_blank" rel="noopener noreferrer"
            class="link lh-1-4 text-caption blue200--text"
          >
            <fai :icon="['fad', 'external-link-alt']" class="mr-1 blue400--text" />
            <span v-text="value" />
          </a>
          <span v-else class="mb-0 text--disabled lh-1-4 text-caption">
            &mdash;
          </span>
        </template>

        <template #item.status="{ value, item }">
          <h6
            class="text-caption"
            :class="value === 3 ? 'green400--text' : 'grey100--text'"
          >
            {{ STATUS_TRADE[value] || value }}
          </h6>
          <h6 class="action-type blue300--text">
            <span class="text-capitalize mr-1" v-text="item.data.type || 'internal'" />
            <span v-if="item.data.method" class="blue400--text">({{ item.data.method }})</span>
          </h6>
        </template>

        <template #item.createdAt="{ value, item }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'info-square']"
            class="text-body-1 link"
            :class="item.error ? 'primary500--text' : 'blue400--text'"
            @click="onExpandItem(item)"
          />
        </template>

        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <v-row>
              <v-col v-if="item.error" cols="12">
                <h5 class="primary500--text text-body-2">
                  Error
                </h5>
                <p class="mb-0 primary200--text lh-1-4 text-caption" v-text="item.error" />
              </v-col>

              <v-col cols="12" md="4">
                <h5 class="grey100--text text-body-2">
                  Type
                </h5>
                <p class="mb-0 text--secondary lh-1-4 text-caption" v-text="item.data.type" />
              </v-col>
              <v-col cols="12" md="4">
                <h5 class="grey100--text text-body-2">
                  Method
                </h5>
                <p class="mb-0 text--secondary lh-1-4 text-caption" v-text="item.data.method" />
              </v-col>
              <v-col cols="12" md="4">
                <h5 class="grey100--text text-body-2">
                  User
                </h5>
                <p v-if="item.data.user" class="mb-0 text--secondary lh-1-4 text-caption" v-text="item.data.user" />
                <p v-else class="mb-0 text--disabled lh-1-4 text-caption">
                  &mdash;
                </p>
              </v-col>

              <v-col v-if="item.items" cols="12">
                <h5 class="grey100--text text-body-2">
                  Items in this trade <span class="blue300--text">(x{{ item.items.length }})</span>
                </h5>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(gameitem, i) in item.items"
                    :key="i"
                    class="game-item"
                  >
                    <v-tooltip top>
                      <template #activator="{on}">
                        <v-img
                          :transition="false"
                          :aspect-ratio="1"
                          width="48"
                          :src="`https://community.cloudflare.steamstatic.com/economy/image/${gameitem.image}/164x164`"
                          contain
                          v-on="on"
                        >
                          <span v-show="gameitem.amount > 1">x{{ gameitem.amount }}</span>
                        </v-img>
                      </template>
                      <span v-text="gameitem.name" />
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-col cols="12" class="mb-3">
                  <v-expansion-panels accordion flat tile>
                    <v-expansion-panel>
                      <v-expansion-panel-header color="grey600">
                        Full bot action object
                      </v-expansion-panel-header>
                      <v-expansion-panel-content color="grey600">
                        <pre v-text="item" />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce, invertObject } from '@/utils'

const TRADE_STATUS = {
  Invalid: 1,
  Active: 2,
  Accepted: 3,
  Countered: 4,
  Expired: 5,
  Canceled: 6,
  Declined: 7,
  InvalidItems: 8,
  CreatedNeedsConfirmation: 9,
  CanceledBySecondFactor: 10,
  InEscrow: 11,
  Errored: 99,
  Complete: 1000,
  Create: 1001,
  Decline: 1002,
  Confirm: 1003,
  Accept: 1004,
  ReceiveCreate: 1005,
  ReceiveAccept: 1006,
  GameRake: 1007,
}

const STATUS_TRADE = invertObject(TRADE_STATUS)

export default {
  data() {
    return {
      loading: false,
      total: 0,
      search: '',
      expanded: [],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Bot', value: 'bot', align: 'start' },
        { text: 'Trade ID', value: 'tradeId', align: 'start' },
        {
          text: 'Status', value: 'status', align: 'start',
        },
        {
          text: 'Created', value: 'createdAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      entries: [],
      inspectedUser: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
      itemdb: 'items/items',
    }),
    STATUS_TRADE() {
      return STATUS_TRADE
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
  },
  methods: {
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    async fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.bots.actions', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        const { itemdb } = this


        this.entries = entries.map(i => {
          let items = []

          if (i.data?.items) {
            items = i.data.items.map(item => ({
              ...itemdb[item.name],
              price: item.price,
            }))
          }

          return {
            ...i,
            id: i._id,
            items: i.data?.items?.[0]?.name ? items : null,
          }
        })
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item.id === expandedItem?.id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    async onCopy(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$toast.info('Copied')
      } catch (err) {
        this.$toast.error('Copy failed, sorry.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.action-type {
  font-size: 10px;
}

pre {
  overflow-x: auto;
  max-height: 250px;
  white-space: pre-wrap;
  word-break: break-all;
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
